function limitOffers(e) {
    const selectedState = e.target.value ? e.target.value : 'all';
    setState(selectedState);
}

function setState(state) {
    /* update all dropdowns to have same value */
    if (document.querySelectorAll('.banking-offer-filter').length > 0) {
        document.querySelectorAll('.banking-offer-filter').forEach((element) => {
            element.value = state;
        });
    }
    /* remove all filtering to show all items in summary table, comparison table, & tile list */
    document.querySelectorAll('[data-states-not-available-in]').forEach((element) => {
        element.classList.remove('d-none');
    });

    /* Check if we have a selectedState */
    if (state !== 'all') {
        /* hide specific items in summary table & tile list & comparison table */
        document.querySelectorAll(`[data-states-not-available-in*=${state} i]`).forEach((element) => {
            element.classList.add('d-none');
        });
    }
}

/**
 * ReadyFunction: once DOM is complete
 *
 *  1. apply filter handlers
 *  2. hide all dropdown except first on page
 *  3. preselect based on geo vars
 */
document.addEventListener('readystatechange', (event) => {
    if (event.target.readyState === 'complete') {
        if (document.querySelectorAll('.banking-filter-dropdown').length > 0) {
            document.querySelectorAll('.banking-offer-filter').forEach((element, index) => {
                element.addEventListener('input', (e) => {
                    limitOffers(e);
                });
            });
        }
    }
});

/* Geo Loc vars are ready,  */
window.addEventListener('geoVarsReady', presetDropDown);

function presetDropDown() {
    if (window.geoStateCode && window.geoCountryCode === 'US') {
        /* set dropdowns with correct geo value */
        if (document.querySelectorAll('.banking-offer-filter').length > 0) {
            document.querySelectorAll('.banking-offer-filter').forEach((element) => {
                element.value = window.geoStateCode;
            });
        }
        setState(window.geoStateCode);
    }
}
